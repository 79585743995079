import { autoinject } from 'aurelia-framework';
import { GetAuditLogResponse, AuditLogsApiClient } from './../../services/cyber-api';
import { StateApi } from './../../services/state-api';

@autoinject
export class AuditLogs {
    public logs: GetAuditLogResponse[];
    public total: number;
    private readonly take: number = 25;
    private skip: number;
    private loading: boolean = true;
    private totalAuditLogsCount: number;

    constructor(private auditApi: AuditLogsApiClient, private state: StateApi) {
    }

    private async attached(): Promise<void> {
        await this.refresh();
    }

    private async refresh(): Promise<void> {
        this.skip = 0;
        this.logs = [];

        // Retrieve the first batch of records, after which next 'pages' will be retrieved upon reaching the end
        // of the infinite-scroller
        await this.fetch(0, true, false);
    }

    private async fetch(topIndex: number, isAtBottom: boolean, isAtTop: boolean): Promise<void> {
        // Only fetch more when scroll position is at the bottom
        if (!isAtBottom) return;

        // When at the end of the list and no more audit logs are available, short-circuit as there's nothing left to
        // fetch
        if (this.totalAuditLogsCount === this.logs.length) return;

        this.loading = true;

        const paged = await this.auditApi.getAll(
            this.state.company(),
            this.take,
            this.skip
        );

        if (!paged) {
            this.loading = false;
            return;
        }

        const items = paged.items;
        this.logs = this.logs.concat(items);
        this.totalAuditLogsCount = paged.total;

        this.skip += this.take;
        this.total = paged.total;
        this.loading = false;
    }
}

import { autoinject } from 'aurelia-dependency-injection';
import { AuthenticationProvider } from 'providers/authentication-provider';

@autoinject
export class Logout {

    constructor(
        private authenticationProvider: AuthenticationProvider
    ) {}

    private attached(): void {
        this.authenticationProvider.logout();
    }
}

import { autoinject } from 'aurelia-framework';
import { NavigationCommand, Router } from 'aurelia-router';
import { LocalStorageHelper, LocalStorageKeys } from 'utilities/local-storage-helper';
import { AuthenticationProvider } from './../../providers/authentication-provider';
import moment from 'moment/moment';

@autoinject
export class LoginCallback {
    constructor(
        private authProvider: AuthenticationProvider,
        private router: Router
    ) { }

    private async canActivate(params: any): Promise<boolean | NavigationCommand> {
        // Initiate handling of authentication when not authenticated
        if (!this.authProvider.isAuthenticated())
            await this.authProvider.handleAuthentication();

        // set lastSignOff to now if it doesn't exist otherwise you need to login again.
        if (LocalStorageHelper.get(LocalStorageKeys.LastSignOff) === null) {
            LocalStorageHelper.set(LocalStorageKeys.LastSignOff, moment.utc());
        }

        // Redirect to a previously set auth return url, otherwise default to root
        const redirectFragment = LocalStorageHelper.get<string>(LocalStorageKeys.AuthReturnUrl) || '/';
        window.location.href = redirectFragment;

        // Never allow the login-callback view to be loaded, this viewmodel's purpose is just to handle auth
        return false;
    }
}

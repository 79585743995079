import { autoinject } from 'aurelia-dependency-injection';
import { Aurelia } from 'aurelia-framework';
import { AuthenticationProvider } from 'providers/authentication-provider';
import { Router } from 'aurelia-router';

@autoinject
export class Login {

    constructor(private app: Aurelia, private auth: AuthenticationProvider, private router: Router) {
    }

    private attached(): void {
        if (!this.auth.isAuthenticated())
            this.auth.login();
    }
}
